import * as bootstrap from 'bootstrap';
import Gradient from 'javascript-color-gradient';

import InternalDataManager from './internal-data-manager.js';
import DetailFestivalModalManager from './detail-festival-modal';
import UserManager from './user-manager.js';

import { displayLoader, hideSelectionModes } from './ui';
import { processSpotifyData, spotifyClientId } from './spotify';
import { SPOTIFY_ACCESS_TOKEN_LS_KEY, getSpotifyAccessToken, getSpotifyRefreshToken, hasSpotifyAccessTokenExpire, redirectToSpotifyAuthCodeFlow } from './spotify/authCodeWithPkce';
import {
    LINEFEST_MODE,
    LINEFEST_SELECTED_FESTIVAL,
    LINEFEST_SELECTED_FESTIVAL_EDITION,
    LINEFEST_SELECTED_FESTIVAL_EDITION_NAME,
    LINEFEST_SELECTED_PROVIDER,
    MODES,
    PROVIDERS,
    code,
    token,
    formatVenueName,
    getFestivalData,
    userFollowArtistOnSelectedProvider,
} from './utils';
import {
    DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY,
    DEEZER_ACCESS_TOKEN_LS_KEY,
    extractAccessTokenFromURLOrLS,
    hasDeezerAccessTokenExpire,
    processDeezerMusicData,
    redirectToDeezerAuthCodeFlow,
    saveDeezerAccessTokenWithExpiresTS,
} from './deezer/index.js';
import { processAppleMusicData } from './apple-music/index.js';
import { getBrowserLanguage } from './i18n-utils.js';
import { LASTFM_SESSION_KEY_LS_KEY, getLastfmSessionKey, lastFMAPIKey, lastFMAPISharedSecret, redirectToLastfmAuthCodeFlow } from './last-fm/index.js';

declare const $: any;
declare const Sentry: any;
declare const i18next: any;
declare const dayjs: any;
declare const LastFMCache: any;
declare const LastFM: any;

let SelectFestivalManagerInstance: any;

export class SelectFestivalManager {
    public $selectionModeLight: any = null;

    public $selectAFestivalButton: any = null;
    public $selectAFestivalPanel: any = null;

    public $loginsBlock: any = null;

    public $artistsList: any = null;

    public $btnSpotifyLogin: any = null;
    public $btnAppleMusicLogin: any = null;
    public $btnDeezerLogin: any = null;
    public $btnLastfmLogin: any = null;

    public $btnCloseSelectedFestival: any = null;

    public $festivalsSlider: any = null;
    public $festivals: any = null;

    public $statistics: any = null;

    public $matcherPanel: any = null;

    public $selectAFestivalTab: any = null;

    public $filtersDatesPanel: any = null;
    public $filtersVenuesPanel: any = null;
    public $filtersMatchesPanel: any = null;

    public $legends: any = null;

    public displayMatches = false;
    public selectedFilterDate = 'a-z';

    public $searchFilters: any = null;
    public $festivalsSelect: any = null;
    public $artistsSelect: any = null;
    public $locationsSelect: any = null;

    constructor() {
        if (!SelectFestivalManagerInstance) {
            SelectFestivalManagerInstance = this;
        }
        return SelectFestivalManagerInstance;
    }

    init() {
        return new Promise((selectFestivalManagerInitResolve, selectFestivalManagerInitReject) => {
            this.$selectAFestivalButton = document.querySelector('.selection-mode .select-a-festival');
            this.$selectAFestivalPanel = document.querySelector('.select-a-festival-panel');

            if (this.$selectAFestivalButton) {
                this.$selectAFestivalButton.addEventListener('click', this.showSelectPanel.bind(this));
            }

            this.$btnSpotifyLogin = document.querySelector('.select-a-festival-panel .btn-spotify-login');

            if (this.$btnSpotifyLogin) {
                this.$btnSpotifyLogin.addEventListener('click', this.loginWithSpotify.bind(this));
            }

            this.$btnAppleMusicLogin = document.querySelector('.select-a-festival-panel .btn-apple-music-login');

            if (this.$btnAppleMusicLogin) {
                this.$btnAppleMusicLogin.addEventListener('click', this.loginWithAppleMusic.bind(this));
            }

            this.$btnDeezerLogin = document.querySelector('.select-a-festival-panel .btn-deezer-login');

            if (this.$btnDeezerLogin) {
                this.$btnDeezerLogin.addEventListener('click', this.loginWithDeezer.bind(this));
            }

            this.$btnLastfmLogin = document.querySelector('.select-a-festival-panel .btn-lastfm-login');

            if (this.$btnLastfmLogin) {
                this.$btnLastfmLogin.addEventListener('click', this.loginWithLastfm.bind(this));
            }

            this.$btnCloseSelectedFestival = document.querySelector('.btn-close-selected-festival');

            if (this.$btnCloseSelectedFestival) {
                this.$btnCloseSelectedFestival.addEventListener('click', this.closeSelectedFestival.bind(this));
            }

            this.$festivalsSlider = document.querySelector('.dance-members-slider');

            this.$matcherPanel = document.querySelector('.festival_artists');

            this.$loginsBlock = document.querySelector('.select-a-festival-panel .logins');

            this.$artistsList = document.querySelector('.artists_list');

            this.$statistics = document.querySelector('.statistics');

            this.$festivals = document.querySelectorAll('.dance-member-slide');
            if (this.$festivals.length > 0) {
                this.$festivals.forEach(($festival: any) => {
                    $festival.addEventListener('click', this.selectFestival.bind(this));
                });
            }

            this.$selectAFestivalTab = document.getElementById('select-a-festival-tab');

            this.$filtersDatesPanel = document.querySelector('.filters-dates');
            this.$filtersVenuesPanel = document.querySelector('.filters-venues');

            this.$legends = document.querySelector('.legends');

            this.$selectionModeLight = document.querySelector('.selection-mode-light');

            this.$searchFilters = document.querySelector('.search-filters');
            this.$festivalsSelect = document.querySelector('.search-filters .festivals-select');
            this.$artistsSelect = document.querySelector('.search-filters .artists-select');
            this.$locationsSelect = document.querySelector('.search-filters .locations-select');

            this.hydrateSearchFilters();

            selectFestivalManagerInitResolve(true);
        });
    }

    hydrateSearchFilters() {
        console.log('hydrateSearchFilters');

        if (this.$festivalsSelect) {
            if (InternalDataManager.festivals && InternalDataManager.festivals.length > 0) {
                let sortedFestivals = [...InternalDataManager.festivals];
                sortedFestivals = sortedFestivals.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                let $options: any = [];
                sortedFestivals.forEach((festival: any) => {
                    const $option = document.createElement('option');
                    $option.innerHTML = festival.name;
                    $option.setAttribute('value', festival.id);
                    $options.push($option);
                });
                this.$festivalsSelect.append(...$options);
                this.$festivalsSelect.addEventListener('change', this.filterFestival.bind(this));
                const firstSelectOption = this.$festivalsSelect.querySelector('option');
                if (firstSelectOption) {
                    firstSelectOption.innerHTML = firstSelectOption.innerHTML + ` (${InternalDataManager.festivals.length})`;
                }
            }
        }
        if (this.$artistsSelect) {
            if (InternalDataManager.artistsWithFestivals) {
                let $options: any = [];
                for (let artist in InternalDataManager.artistsWithFestivals) {
                    const $option = document.createElement('option');
                    $option.innerHTML = artist;
                    $option.setAttribute('value', InternalDataManager.artistsWithFestivals[artist].id);
                    $options.push($option);
                }
                this.$artistsSelect.append(...$options);
                this.$artistsSelect.addEventListener('change', this.filterArtist.bind(this));
            }
        }
        if (this.$locationsSelect) {
            if (InternalDataManager.locations && InternalDataManager.locations.length > 0) {
                const sortedLocations = [...InternalDataManager.locations].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                let $optionsGroups: any = [];
                sortedLocations.forEach((continent: any) => {
                    const $optionGroup = document.createElement('optgroup');
                    $optionGroup.setAttribute('label', continent.name);
                    const countries = [...continent.countries].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                    let $options: any = [];
                    countries.forEach((country: any) => {
                        const $option = document.createElement('option');
                        $option.innerHTML = `${country.name} (${country.nbFestivals})`;
                        $option.setAttribute('value', country.id);
                        if (country.nbFestivals > 0) {
                            $options.push($option);
                        }
                    });
                    if (countries.length > 0) {
                        $optionGroup.append(...$options);
                        $optionsGroups.push($optionGroup);
                    }
                });
                this.$locationsSelect.append(...$optionsGroups);
                this.$locationsSelect.addEventListener('change', this.filterLocation.bind(this));
            }
        }
    }

    filterArtist(ev: any) {
        if (ev.target) {
            const artistId = ev.target.value;
            if (artistId === 'all') {
                this.resetFestivalShowState();
                this.resetMonthsShowState();
            } else {
                const festivalsForSelectedArtist = InternalDataManager.getFestivalsForArtist(artistId);
                const festivalsIDsForSelectedArtist = [...festivalsForSelectedArtist.map((festivalForSelectedArtist) => festivalForSelectedArtist.id)];

                festivalsForSelectedArtist.forEach((festival) => {
                    const startMonth = dayjs(festival.start_datetime).format('MM');
                    const $monthsNamesNotMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide_month_container h3:not([data-month="${startMonth}"])`);
                    $monthsNamesNotMatching.forEach(($monthNamesNotMatching: any) => {
                        $monthNamesNotMatching.classList.add('d-none');
                    });
                });
                festivalsForSelectedArtist.forEach((festival) => {
                    const startMonth = dayjs(festival.start_datetime).format('MM');
                    const $monthMatching = this.$festivalsSlider.querySelector(`.dance-member-slide_month_container h3[data-month="${startMonth}"]`);
                    if ($monthMatching) {
                        $monthMatching.classList.remove('d-none');
                    }
                });

                this.$festivalsSlider.querySelectorAll(`.dance-member-slide`).forEach(($festival) => {
                    const festivalId = $festival.getAttribute('data-festival-id');
                    if (!festivalsIDsForSelectedArtist.includes(parseInt(festivalId, 10))) {
                        $festival.classList.add('d-none');
                    } else {
                        $festival.classList.remove('d-none');
                    }
                });
            }
        }
    }

    filterLocation(ev: any) {
        if (ev.target) {
            const countryId = ev.target.value;
            if (countryId === 'all') {
                this.resetFestivalShowState();
                this.resetMonthsShowState();
            } else {
                const festivals = InternalDataManager.getFestivalsByCountry(countryId);

                festivals.forEach((festival) => {
                    const startMonth = dayjs(festival.start_datetime).format('MM');
                    const $monthsNamesNotMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide_month_container h3:not([data-month="${startMonth}"])`);
                    $monthsNamesNotMatching.forEach(($monthNamesNotMatching: any) => {
                        $monthNamesNotMatching.classList.add('d-none');
                    });
                });
                festivals.forEach((festival) => {
                    const startMonth = dayjs(festival.start_datetime).format('MM');
                    const $monthMatching = this.$festivalsSlider.querySelector(`.dance-member-slide_month_container h3[data-month="${startMonth}"]`);
                    if ($monthMatching) {
                        $monthMatching.classList.remove('d-none');
                    }
                });

                const $festivalsNotMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide:not([data-country-id="${countryId}"])`);
                $festivalsNotMatching.forEach(($festivalNotMatching: any) => {
                    $festivalNotMatching.classList.add('d-none');
                });
                const $festivalsMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide[data-country-id="${countryId}"]`);
                $festivalsMatching.forEach(($festivalMatching: any) => {
                    $festivalMatching.classList.remove('d-none');
                });
            }
        }
    }

    filterFestival(ev: any) {
        if (ev.target) {
            const festivalId = ev.target.value;
            if (festivalId === 'all') {
                this.resetFestivalShowState();
                this.resetMonthsShowState();
            } else {
                const festivalData = InternalDataManager.getFestivalMainInfosById(festivalId);
                const startMonth = dayjs(festivalData.start_datetime).format('MM');

                const $monthsNamesNotMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide_month_container h3:not([data-month="${startMonth}"])`);
                $monthsNamesNotMatching.forEach(($monthNamesNotMatching: any) => {
                    $monthNamesNotMatching.classList.add('d-none');
                });
                const $festivalsNotMatching = this.$festivalsSlider.querySelectorAll(`.dance-member-slide:not([data-festival-id="${festivalId}"])`);
                $festivalsNotMatching.forEach(($festivalNotMatching: any) => {
                    $festivalNotMatching.classList.add('d-none');
                });

                const $festivalMatching = this.$festivalsSlider.querySelector(`.dance-member-slide[data-festival-id="${festivalId}"]`);
                if ($festivalMatching) {
                    $festivalMatching.classList.remove('d-none');
                }
                const $monthMatching = this.$festivalsSlider.querySelector(`.dance-member-slide_month_container h3[data-month="${startMonth}"]`);
                if ($monthMatching) {
                    $monthMatching.classList.remove('d-none');
                }
            }
        }
    }

    resetMonthsShowState() {
        const $months = this.$festivalsSlider.querySelectorAll(`.dance-member-slide_month_container h3`);
        $months.forEach(($month: any) => {
            $month.classList.remove('d-none');
        });
    }

    resetFestivalShowState() {
        const $festivals = this.$festivalsSlider.querySelectorAll(`.dance-member-slide`);
        $festivals.forEach(($festivalNotMatching: any) => {
            $festivalNotMatching.classList.remove('d-none');
        });
    }

    showSelectPanel() {
        if (this.$selectAFestivalPanel) {
            this.$selectAFestivalPanel.classList.remove('d-none');
            hideSelectionModes();
            localStorage.setItem(LINEFEST_MODE, MODES.SELECT);
            if (this.$selectionModeLight) {
                // this.$selectionModeLight.classList.remove('d-none');
                const $selectButton = this.$selectionModeLight.querySelector('.btn[data-action="select-a-festival"]');
                if ($selectButton) {
                    $selectButton.classList.add('active');
                }
                const $suggestButton = this.$selectionModeLight.querySelector('.btn[data-action="suggest-a-festival"]');
                if ($suggestButton) {
                    $suggestButton.classList.remove('active');
                }
            }
        }
    }

    hideSelectPanel() {
        if (this.$selectAFestivalPanel) {
            this.$selectAFestivalPanel.classList.add('d-none');
        }
    }

    hideSelectPanelResults() {
        if (this.$festivalsSlider) {
            this.$festivalsSlider.classList.add('d-none');
        }
        if (this.$searchFilters) {
            this.$searchFilters.classList.add('d-none');
        }
    }

    showSelectPanelLogins() {
        if (this.$matcherPanel) {
            this.$matcherPanel.classList.remove('d-none');
        }
    }

    closeSelectedFestival() {
        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL);
        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION);
        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME);
        if (this.$festivalsSlider) {
            this.$festivalsSlider.classList.remove('d-none');
        }
        const $mainBlockTitle = document.querySelector('#festivals .dance-title');
        if ($mainBlockTitle) {
            $mainBlockTitle.classList.remove('d-none');
        }
        if (this.$matcherPanel) {
            this.$matcherPanel.classList.add('d-none');
            this.$matcherPanel.classList.remove('d-flex');
        }
    }

    showFestivalList() {
        if (this.$festivalsSlider) {
            this.$festivalsSlider.classList.remove('d-none');
        }
        const $mainBlockTitle = document.querySelector('#festivals .dance-title');
        if ($mainBlockTitle) {
            $mainBlockTitle.classList.remove('d-none');
        }
        if (this.$matcherPanel) {
            this.$matcherPanel.classList.add('d-none');
            this.$matcherPanel.classList.remove('d-flex');
        }
    }

    loginWithSpotify() {
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.SPOTIFY);
        redirectToSpotifyAuthCodeFlow(spotifyClientId);
    }

    loginWithLastfm() {
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.LASTFM);
        redirectToLastfmAuthCodeFlow();
    }

    async loginWithAppleMusic() {
        InternalDataManager.selectedProvider = PROVIDERS.APPLE_MUSIC;
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.APPLE_MUSIC);
        this.$loginsBlock.classList.add('d-none');
        displayLoader(this.$artistsList, 'fetching_artists_library');
        try {
            await processAppleMusicData();
            //this.processMatching();
        } catch (error) {
            this.$artistsList.classList.add('d-none');
            this.$loginsBlock.classList.remove('d-none');
            const $loginError = this.$loginsBlock.querySelector('.error');
            if ($loginError) {
                $loginError.innerHTML = i18next.t('music_account_login_error');
            }
        }
    }

    loginWithDeezer() {
        InternalDataManager.selectedProvider = PROVIDERS.DEEZER;
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.DEEZER);
        redirectToDeezerAuthCodeFlow();
        this.$loginsBlock.classList.add('d-none');
    }

    updateStatistics() {
        const currentLanguage = getBrowserLanguage();
        if (this.$statistics) {
            const $artistsVisible = this.$artistsList.querySelectorAll('.artist:not(.d-none)');
            const $artistsMatchedByGenre = this.$artistsList.querySelectorAll('.artist.matched-by-genre:not(.d-none)');
            const $artistsMatchedByRelatedGenre = this.$artistsList.querySelectorAll('.artist.matched-by-related-genre:not(.d-none)');
            const $artistsMatchedBySubscribe = this.$artistsList.querySelectorAll('.artist.matched-by-subscribe:not(.d-none)');
            const finalNumbersOfMatch = $artistsMatchedBySubscribe.length + $artistsMatchedByGenre.length * 0.85 + $artistsMatchedByRelatedGenre.length * 0.75;
            let matchString = '';
            if (currentLanguage === 'fr') {
                matchString = finalNumbersOfMatch > 1 ? 'correspondances' : 'correspondance';
            } else {
                matchString = finalNumbersOfMatch > 1 ? 'matches' : 'match';
            }
            const percent = this.displayMatches
                ? Math.floor((finalNumbersOfMatch * 100) / InternalDataManager.selectedFestivalData.shows.length)
                : Math.floor((finalNumbersOfMatch * 100) / $artistsVisible.length);

            this.$statistics.innerHTML = `${i18next.t('matching_score')} : <span>${percent}%</span>`;
        }
    }

    async selectFestival(ev: any) {
        console.log('selectFestival');
        const festivalSlug = ev.currentTarget.getAttribute('data-festival-slug');
        const festivalId = parseInt(ev.currentTarget.getAttribute('data-festival-id'), 10);
        const festivalYear = parseInt(ev.currentTarget.getAttribute('data-festival-year'), 10);
        const festivalEditionName = ev.currentTarget.getAttribute('data-festival-edition-name');

        ev.preventDefault();

        this.showSelectPanel();
        localStorage.setItem(LINEFEST_SELECTED_FESTIVAL, String(festivalId));
        localStorage.setItem(LINEFEST_SELECTED_FESTIVAL_EDITION, String(festivalYear));
        localStorage.setItem(LINEFEST_MODE, MODES.SELECT);
        if (festivalEditionName !== '') {
            localStorage.setItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME, festivalEditionName);
        }

        InternalDataManager.selectedFestivalData = await getFestivalData(festivalSlug, String(festivalYear), festivalEditionName);

        const $mainBlockTitle = document.querySelector('#festivals .dance-title');
        const $title = document.querySelector('#festivals .selected-festival-title');

        if ($title && UserManager.userFollowingArtists.length === 0) {
            $title.innerHTML = InternalDataManager.selectedFestivalData.name;
            if (InternalDataManager.selectedFestivalData.editionName && InternalDataManager.selectedFestivalData.editionName !== '') {
                $title.innerHTML = InternalDataManager.selectedFestivalData.name + ' - ' + InternalDataManager.selectedFestivalData.editionName;
            }

            if (this.$festivalsSlider) {
                this.$festivalsSlider.classList.add('d-none');
            }

            if ($mainBlockTitle) {
                $mainBlockTitle.classList.add('d-none');
            }

            if (this.$matcherPanel) {
                this.$matcherPanel.classList.remove('d-none');
                this.$matcherPanel.classList.add('d-flex');
            }

            if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                try {
                    await processAppleMusicData();
                    this.showFestivalList();
                    DetailFestivalModalManager.show({
                        festivalId,
                        festivalSlug,
                        festivalYear,
                        festivalEditionName,
                    });
                } catch (error) {
                    this.$artistsList.classList.add('d-none');
                    this.$loginsBlock.classList.remove('d-none');
                    const $loginError = this.$loginsBlock.querySelector('.error');
                    if ($loginError) {
                        $loginError.innerHTML = i18next.t('music_account_login_error');
                    }
                    console.log(error);
                }
            } else {
                if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                    try {
                        let deezerAccessToken: any = await extractAccessTokenFromURLOrLS();

                        if (deezerAccessToken !== '') {
                            /*const localAccessToken = localStorage.getItem(DEEZER_ACCESS_TOKEN_LS_KEY);

                            let accessToken = null;

                            if (!localAccessToken) {
                                accessToken = await getDeezerAccessToken(code);
                            } else {
                                accessToken = localAccessToken;
                                const accessTokenExpirated = hasDeezerAccessTokenExpire();
                                if (accessTokenExpirated) {
                                    accessToken = await getDeezerAccessToken(code);
                                }
                            }*/

                            this.$loginsBlock.classList.add('d-none');

                            if (deezerAccessToken !== '') {
                                try {
                                    displayLoader(this.$artistsList, 'fetching_artists_library');

                                    await processDeezerMusicData(deezerAccessToken);

                                    this.showFestivalList();
                                    DetailFestivalModalManager.show({
                                        festivalId,
                                        festivalSlug,
                                        festivalYear,
                                        festivalEditionName,
                                    });
                                } catch (error: any) {
                                    let e: any = error;
                                    Sentry.captureException(error);
                                    if (this.$artistsList) {
                                        this.$artistsList.classList.add('error');
                                        this.$artistsList.innerHTML = e.toString();
                                    }
                                }
                            }
                        }
                    } catch (error: any) {
                        let e: any = error;
                        Sentry.captureException(error);
                        if (this.$artistsList) {
                            this.$loginsBlock.classList.add('d-none');
                            this.$artistsList.classList.add('error');
                            this.$artistsList.innerHTML = e.toString();

                            if (this.$statistics) {
                                this.$statistics.classList.add('d-none');
                            }
                        }
                    }
                } else if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                    try {
                        const localAccessToken = localStorage.getItem(SPOTIFY_ACCESS_TOKEN_LS_KEY);

                        let accessToken = null;

                        if (!localAccessToken && code) {
                            accessToken = await getSpotifyAccessToken(spotifyClientId, code);
                            let url = new URL(window.location.href);
                            history.pushState({}, '', url.origin + url.pathname);
                        } else {
                            accessToken = localAccessToken;
                            const accessTokenExpirated = hasSpotifyAccessTokenExpire();
                            if (accessTokenExpirated) {
                                accessToken = await getSpotifyRefreshToken(spotifyClientId);
                            }
                        }

                        this.$loginsBlock.classList.add('d-none');

                        if (accessToken) {
                            InternalDataManager.selectedProvider = PROVIDERS.SPOTIFY;
                            try {
                                displayLoader(this.$artistsList, 'fetching_artists_library');

                                await processSpotifyData(accessToken);

                                this.showFestivalList();
                                DetailFestivalModalManager.show({
                                    festivalId,
                                    festivalSlug,
                                    festivalYear,
                                    festivalEditionName,
                                });
                            } catch (error: any) {
                                let e: any = error;
                                Sentry.captureException(error);
                                if (this.$artistsList) {
                                    this.$artistsList.classList.add('error');
                                    this.$artistsList.innerHTML = e.toString();
                                }
                            }
                        } else {
                            if (UserManager.userFollowingArtists.length !== 0) {
                                this.showFestivalList();
                                DetailFestivalModalManager.show({
                                    festivalId,
                                    festivalSlug,
                                    festivalYear,
                                    festivalEditionName,
                                });
                            } else {
                                scroll({
                                    top: document.querySelector('#festivals').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 30,
                                    behavior: 'smooth',
                                });
                                this.$loginsBlock.classList.remove('d-none');
                            }
                        }
                    } catch (error: any) {
                        let e: any = error;
                        Sentry.captureException(error);
                        if (this.$artistsList) {
                            this.$loginsBlock.classList.add('d-none');
                            this.$artistsList.classList.add('error');
                            this.$artistsList.innerHTML = e.toString();

                            if (this.$statistics) {
                                this.$statistics.classList.add('d-none');
                            }
                        }
                    }
                } else if (InternalDataManager.selectedProvider === PROVIDERS.LASTFM) {
                    try {
                        const lastFMCache = new LastFMCache();

                        const lastfm = new LastFM({
                            apiKey: lastFMAPIKey,
                            apiSecret: lastFMAPISharedSecret,
                            cache: lastFMCache,
                        });

                        this.$loginsBlock.classList.add('d-none');

                        lastfm.library.getArtists({
                            success: function (data) {
                                console.log(data);
                            },
                            error: function (code, message) {
                                /* Show error message. */
                            },
                        });
                    } catch (error: any) {
                        let e: any = error;
                        Sentry.captureException(error);
                        if (this.$artistsList) {
                            this.$loginsBlock.classList.add('d-none');
                            this.$artistsList.classList.add('error');
                            this.$artistsList.innerHTML = e.toString();

                            if (this.$statistics) {
                                this.$statistics.classList.add('d-none');
                            }
                        }
                    }
                } else {
                    scroll({
                        top: document.querySelector('#festivals').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 30,
                        behavior: 'smooth',
                    });
                }
            }
        } else {
            DetailFestivalModalManager.show({
                festivalId,
                festivalSlug,
                festivalYear,
                festivalEditionName,
            });
        }
    }

    initWithLocalSelectedFestival() {
        // Init with local saved festival
        const savedFestival = localStorage.getItem(LINEFEST_SELECTED_FESTIVAL);
        const savedFestivalEdition = localStorage.getItem(LINEFEST_SELECTED_FESTIVAL_EDITION);
        const savedFestivalEditionName = localStorage.getItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME);
        if (savedFestival && savedFestivalEdition) {
            let selector =
                savedFestivalEditionName && savedFestivalEditionName !== ''
                    ? '.dance-member-slide[data-festival-id="' + savedFestival + '"][data-festival-year="' + savedFestivalEdition + '"][data-festival-edition-name="' + savedFestivalEditionName + '"]'
                    : '.dance-member-slide[data-festival-id="' + savedFestival + '"][data-festival-year="' + savedFestivalEdition + '"]';
            const $selectedFestival: any = document.querySelector(selector);
            if ($selectedFestival) {
                this.showSelectPanel();
                $selectedFestival.click();
            }
        } else {
            this.showSelectPanel();
        }
    }
}

export default new SelectFestivalManager();
